import React, { useEffect } from "react"
import { GlobalStyles } from "../constants/global-styles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import HeroContainerTrattamenti from "../containers/trattamenti/hero"

import ReviewsContainer from "../containers/home/reviews"

import CardsContainer from "../containers/home/cards"
import "../constants/global.css"

import PreferencesContainer from "../containers/trattamenti/preferences"

import { useDispatch, useSelector } from "react-redux"
import LoadingView from "../components/LoadingView/LoadingView"
import {
  fetchChiruAsync,
  selectBannerChirurgia,
  selectChiruChirurghi,
  selectChiruLoading,
  selectChiruPrefs,
  selectChiruReviews,
  selectChiruSearchItems,
} from "../redux/chirurgiaSlice"

const theme = {
  main: "#264A79",
  lightblue: "#5492E3",
  gray: "#797979",
}

const ChirurgiaEsteticaPage = () => {
  const dispatch = useDispatch()
  const reviews = useSelector(selectChiruReviews)
  const chirurghi = useSelector(selectChiruChirurghi)
  const prefs = useSelector(selectChiruPrefs)
  const loading = useSelector(selectChiruLoading)
  const searchItems = useSelector(selectChiruSearchItems)
  const banner = useSelector(selectBannerChirurgia)

  useEffect(() => {
    dispatch(fetchChiruAsync())
  }, [])

  if (loading) {
    return <LoadingView />
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Layout>
        <SEO title="Chirurgia Estetica" />
        <HeroContainerTrattamenti searchItems={searchItems} banner={banner} />
        <PreferencesContainer Prefs={prefs} />
        <CardsContainer doctors={chirurghi} />
        <ReviewsContainer reviews={reviews} style={{ paddingBottom: 80 }} />
      </Layout>
    </ThemeProvider>
  )
}

export default ChirurgiaEsteticaPage
