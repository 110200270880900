import { Autocomplete } from "@material-ui/lab"
import React, { useState } from "react"
import HeroSection from "../../components/HeroSection/HeroSection"
import { navigate } from "gatsby"
import navigateToTrattamento from "../../functions/navigateToTrattamento"
import useBanner from "../../hooks/useBanner"
import { Markup } from "interweave"

export default function HeroContainerTrattamenti({
  title,
  searchItems,
  banner,
}) {
  const [value, setValue] = useState("")

  const [desc, foto] = useBanner(banner)

  const handleSubmit = () => {
    if (!value || !value?.id_sys) return
    navigateToTrattamento(value)
  }

  return (
    <HeroSection page={title || null} img={foto} title={"trattamenti"}>
      <HeroSection.FeatureOutlineWrapper>
        <HeroSection.FeatureOutline>
          <Markup content={desc} />
        </HeroSection.FeatureOutline>
      </HeroSection.FeatureOutlineWrapper>
      <HeroSection.BottomOverlay title="trattamenti">
        <HeroSection.BottomOverlayInner>
          <HeroSection.SearchContainer title="trattamenti">
            <Autocomplete
              freeSolo
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue)
              }}
              options={searchItems}
              getOptionLabel={option => option.trattamento}
              renderInput={params => (
                <HeroSection.SearchLeft
                  title="trattamenti"
                  {...params}
                  label="Cerca un trattamento"
                />
              )}
            />
            <HeroSection.SearchButton
              title={"trattamenti"}
              onClick={handleSubmit}
              img={require("../../images/Search.png")}
            ></HeroSection.SearchButton>
          </HeroSection.SearchContainer>
        </HeroSection.BottomOverlayInner>
      </HeroSection.BottomOverlay>
    </HeroSection>
  )
}
